import Notify from "bnc-notify";
import { init } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import trezorModule from '@web3-onboard/trezor';
import ledgerModule from '@web3-onboard/ledger';
import walletConnectModule from '@web3-onboard/walletconnect';
import walletLinkModule from '@web3-onboard/walletlink';
//import portisModule from '@web3-onboard/portis';
//import fortmaticModule from '@web3-onboard/fortmatic';
import torusModule from '@web3-onboard/torus';
import keepkeyModule from '@web3-onboard/keepkey';
import gnosisModule from '@web3-onboard/gnosis';
import Logo from '../logo.jpeg';

const dappId = process.env.REACT_APP_BLOCKNATIVE_KEY;
const rpcUrl = process.env.REACT_APP_JSONRPC;
const networkId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
const appUrl = 'strokes.gmi.sh';
const email = 'mail@gmi.sh';
const name = 'Strokes by gmi.sh';
const description = name;

const injected = injectedModule();
const walletLink = walletLinkModule();
const walletConnect = walletConnectModule();
const torus = torusModule();
const ledger = ledgerModule();
const keepkey = keepkeyModule();
const gnosis = gnosisModule();
const trezor = trezorModule({email, appUrl: 'https://' + appUrl});

export function initNotify() {
    return Notify({
        dappId,
        networkId,
        onerror: error => console.log(`Notify error: ${error.message}`),
        desktopPosition: 'topRight',
    })
}

export const initWeb3Onboard = init({
    wallets: [
        injected,
        ledger,
        walletLink,
        trezor,
        walletConnect,
        gnosis,
        //fortmatic,
        keepkey,
        //portis,
        torus
    ],
    chains: [
        {
            id: '0x1',
            token: 'ETH',
            label: 'Ethereum Mainnet',
            rpcUrl
        }
    ],
    appMetadata: {
        name,
        description,
        icon: Logo,
        logo: Logo,
        recommendedInjectedWallets: [
            { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
            { name: 'MetaMask', url: 'https://metamask.io' }
        ]
    }
})