import {useCallback, useState, useEffect, useRef} from "react";
import {initWeb3Onboard, initNotify} from "./utils/wallets";
import {contractNouns, contractSubNouns, contractSubNounsMint} from "./utils/contract";
import {useConnectWallet, useWallets} from "@web3-onboard/react";
import {useLocalStorage} from "react-use";
import {ethers} from 'ethers';

export default function App() {
    const [localWallets, setLocalWallets, removeLocalWallets] = useLocalStorage('wallets', []);
    const [{ wallet }, connect, disconnect] = useConnectWallet();
    //const [{ chains, connectedChain, settingChain }, setChain] = useSetChain();
    const connectedWallets = useWallets();
    const [web3Onboard, setWeb3Onboard] = useState(null);
    const [notify, setNotify] = useState(null);
    const [amount, setAmount] = useState(1);
    const [totalSupply, setTotalSupply] = useState(null);
    const [nounsTotalSupply, setNounsTotalSupply] = useState(null);
    const [gallery, setGallery] = useState([]);
    const onChangeAmount = useCallback(event => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) setAmount(1);
        else if (value < 1) setAmount(1);
        else if (totalSupply + value > nounsTotalSupply) setAmount(nounsTotalSupply - totalSupply);
        else setAmount(value);
    }, [totalSupply, nounsTotalSupply]);
    const onClickConnect = useCallback(() => {
        connect();
    }, [connect]);
    const onClickDisconnect = useCallback(() => {
        disconnect(wallet);
        removeLocalWallets();
    }, [wallet, disconnect, removeLocalWallets]);
    const onClickMint = useCallback(() => {
        (async () => {
            try {
                const tx = await contractSubNounsMint(wallet.provider)(amount, {value: ethers.utils.parseEther('0.035').mul(amount)});
                const { emitter } = notify.hash(tx.hash);
                emitter.on('txConfirmed', async () => {
                    setTotalSupply((await contractSubNouns.totalSupply()).toNumber());
                });
            } catch (e) {
                notify.notification({eventCode: 'mint', type: 'error', message: e.reason});
            }
        })();
    }, [wallet, amount, notify]);
    useEffect(() => {
        setWeb3Onboard(initWeb3Onboard);
        setNotify(initNotify());
        (async () => {
            setTotalSupply((await contractSubNouns.totalSupply()).toNumber());
            setNounsTotalSupply((await contractNouns.totalSupply()).toNumber());
        })();
    }, []);
    useEffect(() => {
        if (connectedWallets.length > 0) {
            setLocalWallets(connectedWallets.map(({ label }) => label));
        }
    }, [connectedWallets, setLocalWallets]);
    useEffect(() => {
        if (localWallets && localWallets.length > 0) {
            (async () => {
                await connect({ autoSelect: localWallets[0]});
            })();
        }
    }, [web3Onboard, connect, localWallets]);
    useEffect(() => {
        if (totalSupply === null) return;
        (async () => {
            setGallery([]);
            for (let id = totalSupply - 1; id >= totalSupply - 4; id--) {
                const base64 = await contractSubNouns.tokenURI(id);
                setGallery(gallery => [...gallery, {id, url: JSON.parse(atob(base64.substring(29))).image}]);
            }
        })();
    }, [totalSupply, setGallery]);
    return (
        <>
            <div className="w-full max-w-screen-lg p-8 mx-auto flex justify-between text-xl">
                <a href="/"><img src="/logo.svg" alt="Evil Nouns" className={"h-8"} /></a>
                {(wallet !== null && wallet.accounts.length > 0) && <div>{wallet.accounts[0].address.slice(0, 5) + '...' + wallet.accounts[0].address.slice(-4)}, <button onClick={onClickDisconnect}>logout</button></div>}
            </div>
            <div className="w-full max-w-screen-lg px-8 mx-auto grid lg:grid-cols-2 lg:gap-40 text-xl">
                <div>
                    <p className="pb-4">
                        "Evil Nouns" is a completely on-chain derivative of Nouns.
                    </p>
                    <ul className="list-disc ml-6 pb-4">
                        <li>An Evil Noun for each Noun.</li>
                        <li>The mint price Ξ 0.035 consists of <a href="https://en.wikipedia.org/wiki/Evil_number" target="_blank" rel="noreferrer" className="font-medium">evil numbers</a>.</li>
                        <li>10% of the mint payments go to the Nouns DAO.</li>
                        <li>Part of <a href="https://subnouns.wtf" target="_blank" rel="noreferrer" className="font-medium">SubNouns</a> project.</li>
                    </ul>
                    {totalSupply !== null && totalSupply === nounsTotalSupply ? (<div>
                        <b>Sold out!</b> Come back tomorrow when the new Noun is born.
                    </div>) : (<div>
                        {(wallet === null || wallet.accounts.length === 0) ? (
                            <button onClick={onClickConnect} className="bg-black text-white p-2 w-full">Connect Wallet</button>
                        ) : (<>
                            <input type="number" value={amount} onChange={onChangeAmount} className="w-full p-2 border border-black my-2 outline-0"/>
                            <button onClick={onClickMint} className="bg-black text-white p-2 w-full">Mint for Ξ {(amount * 0.035).toFixed(3)}</button>
                        </>)}
                    </div>)}
                    <hr className="mt-2 mb-2"/>
                    <p>
                        {totalSupply !== null ? totalSupply : <>&hellip;</>}/{nounsTotalSupply !== null ? nounsTotalSupply : <>&hellip;</>} minted
                    </p>
                    <div className="my-2 flex items-center space-x-3">
                        <a href="https://nouns.wtf" target="_blank" rel="noreferrer"><img alt="CC0" className="h-6" src="https://nouns.wtf/static/media/logo.f217962c.svg"/></a>
                        <a href="https://looksrare.org/collections/0x3b801b4D94485824B37aE5846FbBAF94Aa842205" target="_blank" rel="noreferrer"><img alt="OpenSea" className="h-4" src="/h-lightbg.svg"/></a>
                        <a href="https://opensea.io/collection/evil-nouns" target="_blank" rel="noreferrer"><img alt="OpenSea" className="h-6" src="https://storage.googleapis.com/opensea-static/Logomark/OpenSea-Full-Logo%20(dark).svg"/></a>
                        <a href="https://etherscan.io/address/0x3b801b4D94485824B37aE5846FbBAF94Aa842205#code" target="_blank" rel="noreferrer"><img alt="Etherscan" className="h-6" src="https://etherscan.io/assets/svg/logos/logo-etherscan.svg?v=0.0.2"/></a>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-4">
                        {gallery.map(({url, id}) => (
                            <div key={id}>
                                <div>Evil Noun #{id}</div>
                                <img className="w-full aspect-square" src={url} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}