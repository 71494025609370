import {ethers} from "ethers";

const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JSONRPC);
const contractNouns = new ethers.Contract('0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03', [
    'function totalSupply() view returns (uint256)',
], provider);
const contractSubNouns = new ethers.Contract(process.env.REACT_APP_SUBNOUNS_CONTRACT, [
    'function totalSupply() view returns (uint256)',
    'function tokenURI(uint256) view returns (string)',
], provider);
const contractSubNounsMint = (web3Provider) => {
    const provider = new ethers.providers.Web3Provider(web3Provider);
    const contract = new ethers.Contract(process.env.REACT_APP_SUBNOUNS_CONTRACT, ['function mint(uint256) payable'], provider.getUncheckedSigner());
    return contract.mint;
}

export {contractNouns, contractSubNouns, contractSubNounsMint, provider}